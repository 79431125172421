var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[(_vm.loading)?_c('Loading'):_vm._e(),_c('a',{staticClass:"sideline",attrs:{"href":_vm.lineHref,"target":"_blank"}},[_c('img',{staticClass:"logo-line mb-5",staticStyle:{"width":"30px"},attrs:{"src":'/images/logos/line.png'}})]),_c('a-row',{attrs:{"type":"flex","gutter":[24, 24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('img',{staticStyle:{"border-radius":"50px"},attrs:{"src":"images/343079958_1295177444709711_9220476016699572912_n.jpg","alt":""}})]),_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{ span: 12, offset: 0 },"xl":{ span: 6, offset: 2 }}},[_c('h4',{staticClass:"mb-15",staticStyle:{"color":"white"}},[_vm._v("สมัครสมาชิก")]),_c('a-button',{staticClass:"register-button mb-10",attrs:{"block":"","href":_vm.registerHref,"target":"_blank"}},[_vm._v(" สมัครสมาชิก")]),_c('h1',{staticClass:"mb-15",staticStyle:{"color":"white"}},[_vm._v("เข้าสู่ระบบ")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Username","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              {
                rules: [
                  { required: true, message: 'Please input your username!' } ],
              } ]),expression:"[\n              'username',\n              {\n                rules: [\n                  { required: true, message: 'Please input your username!' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Username"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  { required: true, message: 'Please input your password!' } ],
              } ]),expression:"[\n              'password',\n              {\n                rules: [\n                  { required: true, message: 'Please input your password!' },\n                ],\n              },\n            ]"}],attrs:{"type":"password","placeholder":"Password"}})],1),(_vm.error)?_c('p',{staticClass:"text-center text-danger"},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e(),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"block":"","html-type":"submit","type":"primary"}},[_vm._v(" เข้าสู่ระบบ ")])],1)],1),_c('p',{staticClass:"mt-10 text-white"},[_vm._v(" *หมายเหตุ เข้าใช้งานยูสและรหัส reno69 เท่านั้น ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }